
























































































































































































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsPersonalInformationProofOfAddressViewModel
  from '@/vue-app/view-models/components/contract-savings/personal-information/contract-savings-personal-information-proof-of-address-allianz-view-model';

@Component({
  name: 'ContractSavingsPersonalInformationAddress',
  components: {
    AddressAllianz: () => import('./address/AddressAllianz.vue'),
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsPersonalInformationAddress extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  personal_information_view_model
    = Vue.observable(new ContractSavingsPersonalInformationProofOfAddressViewModel(this));

  @Watch('personal_information_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('personal_information_view_model.address_proof')
  onFileChange(file: File) {
    if (file) {
      this.personal_information_view_model.setFileData(file);
    }
  }

  @Watch('personal_information_view_model.full_address_on_ine')
  async onFullAddressOnIneChange(full_address_on_ine: string) {
    this.personal_information_view_model.updateDocumentTypeOnChangeFullAddressOnIne(
      full_address_on_ine,
    );
  }

  async nextStep() {
    await this.personal_information_view_model.nextStep(this.$vuetify.breakpoint.smAndDown);
  }

  async created() {
    await this.personal_information_view_model.initialize(getScreenSizeVariant());
  }
}

