import { render, staticRenderFns } from "./ContractSavingsPersonalInformationProofOfAddressAllianz.vue?vue&type=template&id=6c7ada30&"
import script from "./ContractSavingsPersonalInformationProofOfAddressAllianz.vue?vue&type=script&lang=ts&"
export * from "./ContractSavingsPersonalInformationProofOfAddressAllianz.vue?vue&type=script&lang=ts&"
import style0 from "./ContractSavingsPersonalInformationProofOfAddressAllianz.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VCol,VDivider,VRadio,VRadioGroup,VRow})
